<template>
  <div class="padding-card grey">
    <v-card class="padding-card">
      <p class="title-user-scr"> <v-icon> mdi-filter </v-icon>Filtros</p>
      <v-form ref="formFilters">
        <div>
          <v-row>
            <v-col>
              <v-text-field 
                dense 
                :rules="rules.manzana"
                placeholder="99" 
                v-model="filter.manzana" 
                maxlength="2"    
                minlength="2"     
                label="Manzana"></v-text-field>
            </v-col>
            <v-col>              
              <v-text-field 
                dense 
                :rules="rules.lonsm" 
                v-model="filter.street"                
                label="Calle"></v-text-field>   
            </v-col>
            <v-col>       
              <v-autocomplete
                  dense
                  item-text="title"            
                  return-object
                  v-model="filter.statusDebt"
                  :items="['normal', 'moroso', 'en proceso de pago']"
                  filled
                  label="Status financiero"
                >
              </v-autocomplete>    
            </v-col>    
            <v-col>
              <v-autocomplete
                dense
                item-text="title"            
                return-object     
                v-model="filter.legalStatus"         
                :items="['escriturado', 'financiado' ]"
                filled
                label="Status Predio"
              >
            </v-autocomplete>  
            </v-col>     
            </v-row>
            <v-row>     
              <v-col>
                <v-switch
                  v-model="filter.contact"
                  label="Contacto secundario"
                  hide-details
                  inset
                ></v-switch>
              </v-col>          
              <v-col>
                <v-switch
                  v-model="filter.hasWhats"
                  label="En WhatsApp"
                  hide-details
                  inset
                ></v-switch>
              </v-col>             
              <v-col>
                <v-switch
                  dense
                  v-model="filter.numPets"
                  label="Mascotas"
                  hide-details
                  inset
                ></v-switch>
              </v-col>                      
            </v-row>
            <v-row>
              <v-col>
                <v-switch
                  v-model="filter.tags"
                  label="Tags"
                  hide-details
                  inset
                ></v-switch>
              </v-col>    
              <v-col>
                <v-switch
                  v-model="filter.adeudo"
                  label="Adeudos años anteriores"
                  hide-details
                  inset
                ></v-switch>
              </v-col>
              <v-col>
                <v-switch
                  v-model="filter.sanctions"
                  label="Sanciones"
                  hide-details
                  inset
                ></v-switch>
              </v-col>
            </v-row>
          </div>
          <div style="margin:30px">
            <v-divider></v-divider>
          </div>
          <div style="margin-top: 1.5rem;">
            <v-row>
              <v-col>
                <p class="title-user-scr"> <v-icon> mdi-list-box </v-icon>Campos a incluir</p>
              </v-col>
              <v-col>
                <v-switch
                  v-model="allFldsSwitch"
                  color="primary"
                  label="Todos"
                  value="allFldsSwitch"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" v-for="(item, index) in fldList" :key="index">
                <v-switch
                v-model="fldToPrint"
                color="primary"
                :label="item.name"
                :value="item.model"
                hide-details
                ></v-switch>
              </v-col>
            </v-row>           
          </div>          
        <v-card-actions style="margin-top: 1.5rem;">
          <v-btn depressed @click="printReport" color="#00aff2" dark>Vista Previa</v-btn>
          <v-spacer></v-spacer>          
        </v-card-actions>
      </v-form>
    </v-card>
  </div>  
</template>

<script>
import adminUserService from '@/services/admin/users';

export default {
  name: 'UserReportForm',  
  data() {
    return {      
      filter:{
        manzana: null,       
        street: null,
        legalStatus: null,
        statusDebt:  null,
        contact: null,
        hasWhats: null,
        numPets: null,
        tags: null,
        adeudo: null,
        sanctions: null,
      },   
      allFldsSwitch: false,
      allFlds: ['lotPurchased', 'username', 'lotenum', 'manzana', 'street', 'placeOfResidence', 'mobilePhone', 'email',
        'statusDebt', 'legalStatus', 'contactName', 'contactMobile', 'contactEmail', 'tag1', 'tag2', 'tag3', 'debt1', 
        'debt2', 'debt3', 'debt4', 'debt5', 'numPets', 'docuPets', 'hasWhats', 'coments', 'sanctions'
      ],  
      fldToPrint:['lotPurchased', 'username'],
      fldList:[ {name:'Username', model:'lotPurchased'},
          {name: 'Propietario', model: 'username'},
          {name: 'Lote', model: 'lotenum'},
          {name: 'Manzana', model: 'manzana'},
          {name: 'Calle', model:'street'},
          {name: 'Lugar residencia',model:'placeOfResidence'},
          {name: 'celular', model:'mobilePhone'},
          {name: 'Correo',model:'email'},
          {name: 'Status financiero',model:'statusDebt'},
          {name: 'Status predio',model:'legalStatus'},
          {name: 'Nombre contacto secundario', model: 'contactName'},
          {name: 'Celular contacto secundario',model: 'contactMobile'},
          {name: 'Correo contacto secundario', model: 'contactEmail'},
          {name: 'Incluido WhatsApp', model: 'hasWhats'},
          {name: 'Número mascotas', model: 'numPets'}, {name: 'Documentación mascotas', model: 'docuPets'},
          {name: 'Tag1',model: 'tag1'}, {name: 'Tag2',model: 'tag2'}, 
          {name: 'Tag3',model: 'tag3'},
          {name: 'Adeudo 2021', model: 'debt1'}, {name: 'Adeudo 2022', model: 'debt2'}, 
          {name: 'Adeudo 2023', model: 'debt3'}, {name: 'Adeudo 2024', model: 'debt4'}, 
          {name: 'Adeudo 2025', model: 'debt5'}, {name: 'Sanciones', model: 'sanctions'}, 
          {name: 'Comentarios', model: 'coments'},
      ],
      rules: {
          lonsm: [ (v) => !v || v.length <= 30 || 'Nombre debe ser menor a 30 caracteres' ],          
          manzana:[             
            (v) => !v || /^[0-9]*$/.test(v) || 'Solo números 0-9 son permitidos', 
            (v) => (!v || v.length >= 2) || 'Longitud 2 caratéres',
          ],
        },    
    }
  },
  methods: {
    printReport() {                   
      let opts = this.filterNonNullValues(this.filter);      
      adminUserService.getDataRep(opts, this.fldToPrint).then((response) => {
        const dataRep = response.data;        
        console.log(dataRep);
        this.$router.push({
            name: 'userreport',
            params: {                
               dataRep: dataRep,
               fields: this.fldToPrint
            }
        });       
      });
    },
    buildFilterOpt(opts) {
      const newObj = {};

      for (const [key, value] of Object.entries(opts)) {
        if (key === 'numPets' && value === true) {
          newObj[key] = '>0'; 
        } else if (key === 'sanctions' && value === true) {
          newObj[key] = '';
        } else if (key === 'adeudo' && value === true) {
          newObj[2021] = '>0'; 
          newObj[2022] = '>0'; 
          newObj[2023] = '>0'; 
          newObj[2024] = '>0'; 
          newObj[2024] = '>0'; 
        } else {
          newObj[key] = value; 
        }
      }            
      return newObj;      
    },
   filterNonNullValues (obj) {
    return Object.fromEntries(
      Object.entries(obj).filter(([, value]) => {               
        return value !== null;
      })
    );
  }
},
watch: {
  'filter.hasWhats' (newVal) {
    if (newVal != null) {
      this.fldToPrint.push('hasWhats');
    }
  },
  'filter.numPets' (newVal) {
    if (newVal != null) {
      this.fldToPrint.push('numPets', 'docuPets');
    }
  },
  'filter.tags' (newVal) {
    if (newVal != null) {
      this.fldToPrint.push('tag1', 'tag2', 'tag3');
    }
  },
  'filter.adeudo' (newVal) {
    if (newVal != null) {
      this.fldToPrint.push('debt1', 'debt2', 'debt3', 'debt4', 'debt5');
    }
  },
  'filter.contact' (newVal) {
    if (newVal != null) {
      this.fldToPrint.push('contactName', 'contactMobile', 'contactEmail');
    }
  },
  'filter.statusDebt' (newVal) {
    if (newVal != null) {
      this.fldToPrint.push('statusDebt');
    }
  },
  'filter.legalStatus' (newVal) {
    if (newVal != null) {
      this.fldToPrint.push('legalStatus');
    }
  },
  'filter.sanctions' (newVal) {
    if (newVal != null) {
      this.fldToPrint.push('sanctions');
    }
  },
  allFldsSwitch(newVal) {
    if (newVal) {
      this.fldToPrint = this.allFlds;
    } else if (!newVal) {
      this.fldToPrint = ['lotPurchased','username'];
    }
  }
}}
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>